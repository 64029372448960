import React, { useEffect, useState } from "react";
import "./App.css";

function App() {
  const [localTime, setLocalTime] = useState("");

  useEffect(() => {
    const getLocalTime = () => {
      const now = new Date();
      const options = {
        timeZone: "America/Los_Angeles",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      return now.toLocaleTimeString(undefined, options);
    };

    const intervalId = setInterval(() => {
      setLocalTime(getLocalTime());
    }, 1000);

    setLocalTime(getLocalTime());
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="App">
      <main>
        <section>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              height={80}
              width={80}
              src={require("./assets/nikhil.png")}
              alt="Nikhil Mehta"
              style={{ marginRight: "20px" }}
            />
            <div>
              <h1>Nikhil Mehta</h1>
              <p className="contact-info">n.43.mehta@gmail.com</p>
            </div>
          </div>
          <p>
            Hey, I'm Nikhil. I work on the performance engineering team at{" "}
            <a
              href="https://www.anthropic.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Anthropic
            </a>!
          </p>
        </section>
        <section>
          <h2>Interests</h2>
          <p>
            I love my work a lot and I'm passionately curious, so I spend most
            of my free time learning.
          </p>
          <p>
            Outside of work, I really love <a href="https://www.youtube.com/watch?v=u61UEPgUi8M">music</a>, movies, caffeine, and
            spending time with my incredible friends. My goals in life are to
            love unconditionally, understand the universe, contribute something
            meaningful back, and live in the present moment as much as possible.
          </p>
          <p>
            I think existence is really meaningful and to me the meaning of life
            is to make the most of it. Please say hi if we're into some of the same things!
          </p>
        </section>
      </main>
      <footer>
        <p>{localTime.toLowerCase()} · San Francisco, CA</p>
      </footer>
    </div>
  );
}

export default App;
